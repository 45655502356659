export default {
  colors: {
    primary: "#ff1493",
    bodyBg: "#ffffff",
    bodyColor: "#131415",
    blogTitle: "#FFFA96",
    modes: {
      dark: {
        bodyColor: "#ffffff",
        bodyBg: "#0e0e0e",
        blogTitle: "#c792ea",
      },
    },
  },
};
