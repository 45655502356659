/** @jsx jsx */
import { jsx } from "theme-ui";
import { preToCodeBlock } from "mdx-utils";
import Highlight, { defaultProps } from "prism-react-renderer";
import dracula from "prism-react-renderer/themes/dracula";

import "./CodeBlock.css";

const CodeBlock = (props) => {
  const codeProps = preToCodeBlock(props);

  if (!codeProps) {
    return <pre {...props} />;
  }

  const { codeString, language } = codeProps;

  return (
    <Highlight
      {...defaultProps}
      code={codeString}
      language={language}
      theme={dracula}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre
          className={`${className} codeblock`}
          style={style}
          sx={{
            p: 2,
            overflowX: "auto",
            variant: "prism-highlight",
          }}
        >
          {tokens.map((line, i) => (
            <div {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
};

export default CodeBlock;
